@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;900&family=Ubuntu+Mono&display=swap');

@mixin blue-hyperlinks {
  font-weight: var(--fw-bold);
  transition: calc(var(--util-speed) * 2.5);
  color: hsl(var(--clr-accent));
}

@mixin font-settings {
	line-height: 1.8;
	font-size: 1.125rem;
}

:root {
	--clr-light: 0 0% 100%;
	--clr-dark: 0 0% 15.7%;
	--clr-heart: 2 80% 52%;
	--clr-accent: 209.2 92.7% 62.2%;

	/* source: https://brandcolors.net/ */
	--clr-discord: 226.7 58.4% 65.1%;
	--clr-github: 213.8 50.4% 50.2%;
	--clr-instagram: 339.7 74.7% 53.5%;
	--clr-reddit: 16.2 100% 50%;
	--clr-telegram: 200 100% 40%;
	--clr-tiktok: 178.3 90.4% 55.1%;
	--clr-twitch: 264.3 100% 63.7%;

	--clr-blog: hsl(var(--clr-light));
	--clr-web: hsl(var(--clr-light));
	--clr-newsletter: hsl(var(--clr-light));

	--ff-sans: 'Raleway', sans-serif;
	--ff-mono: 'Ubuntu Mono', monospace;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-bold: 700;
	--fw-black: 900;

	--util-speed: 100ms;
	--util-header-height: 4rem;

	--card-border-color: #ddd;
	--card-bg-color: #fff;
	--title-color: #333;
	--title-hover-color: #0073e6;
	--description-color: #666;
	--box-shadow-color: rgba(0, 0, 0, 0.1);
}

*, *::before, *::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: var(--ff-sans);
	font-weight: var(--fw-medium);
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	color: hsl(var(--clr-dark));
}

::selection {
	background: hsl(var(--clr-dark));
	color: hsl(var(--clr-light));
}

.code {
	font-family: var(--ff-mono);
}

.section {
	padding: min(10vh, 10em) 0;

	&.--about {
		height: 60vh;
		background: url('/assets/images/blog/hero-mobile.jpg') no-repeat center;
		background-size: cover;
		@media (min-width: 820px) {
			background-image: url('/assets/images/blog/hero-tablet.jpg');
		}
	
		@media (min-width: 1500px) {
			background-image: url('/assets/images/blog/hero.jpg');
		}
	}


	&.--intro {
		

		@media (min-width: 48em) {
			.split {
				display: grid;
				grid: auto / 50% auto;
				gap: 3em;
			}
		}

		p {
			line-height: 1.8;
			hyphens: auto;
			font-size: 1.125rem;
			

			@media (min-width: 48em) {
				font-size: 1rem;
				line-height: 1.8;
			}

			@media (min-width: 64.5em) {
				font-size: 1.125rem;
			}

			&.section__text {
				margin-block-end: 3rem;

				@media (min-width: 48em) {
					margin: 0;
				}

        a {
          @include blue-hyperlinks();
				}
			}

			.bold {
				font-weight: var(--fw-bold);
				display: flex;
				align-items: center;
				gap: 0.5em;
				margin-block-start: 0.5em;

				span {
					color: hsl(var(--clr-heart));
					font-size: clamp(1em, 2vw, 1.5em);
				}
			}

			&.section__tip {
				padding: 2rem 1.25rem;
				background: hsl(var(--clr-accent));
				color: hsl(var(--clr-light));

				a {
          			@include blue-hyperlinks();
					color: #fff;
					text-decoration: underline;
				}

				@media (min-width: 48em) {
					width: 100%;
					margin: 0;
					padding: 2.5em;
					align-self: start;
				}
			}
		}
	}

	&.--social {
		margin-block-start: auto;

		.container {
			@media (min-width: 48em) {
				display: grid;
				grid: auto / 50% 1fr;
			}
		}
	}

	&__title {
		font-size: 1.5rem;
		font-weight: var(--fw-black);
		position: relative;
		margin-block-end: 1em;

		@media (min-width: 48em) {
			font-size: 3rem;
		}
		@media (min-width: 64.5em) {
			font-size: 4rem;
		}

		&::before {
			position: absolute;
			content: '';
			width: 3rem;
			height: 0.5rem;
			background: hsl(var(--clr-dark));
			inset-inline-start: 0;
			inset-block-start: -0.5rem;
		}


    	a {
    	  @include blue-hyperlinks();
  	    }

		&.--light {
			color: hsl(var(--clr-light));

			&::before {
				background: hsl(var(--clr-light));
			}
		}
	}

	&__description {
		@include font-settings();
		margin-bottom: 40px;
	}

	&.--content {
		padding-block-start: 0;

		.container {
			display: grid;
			grid: auto / 1fr 1fr;
			gap: 0.5rem;

			@media (min-width: 48em) {
				grid: auto / repeat(4, 1fr);
				gap: 1em;
			}
		}

		a {
			border: 1px solid hsl(var(--clr-dark));
			width: 100%;
			aspect-ratio: 1;
			padding: 2rem;

			i {
				transition: calc(var(--util-speed) * 2.5);
			}

			&:hover, &:focus {
				i {
					color: hsl(var(--clr-accent));
				}
			}
		}

		.card__icon {
			width: 100%;
			aspect-ratio: 1;
			display: grid;
			place-content: center;

			i {
				font-size: 3rem;
				color: hsl(var(--clr-dark) / 0.25);

				@media (min-width: 64.5em) {
					font-size: 6rem;
				}
			}
		}

		.card__desc {
			text-align: center;
			font-size: 0.875rem;
			font-weight: var(--fw-bold);
			color: hsl(var(--clr-dark));

			@media (min-width: 48em) {
				font-size: 1rem;
			}

			@media (min-width: 64.5em) {
				font-size: 1.125rem;
			}
		}
	}
  &.--book {
    p {
      font-size: 1rem;
      line-height: 1.8;
      margin-block-end: 1em;
    }

    .title {
      font-weight: bold;
    }
    .answer {
    }

    a {
      font-weight: var(--fw-bold);
      transition: calc(var(--util-speed) * 2.5);
      color: hsl(var(--clr-accent));
    }

    .section__title--faq{
      margin-top: 2rem;
      margin-block-end: 2rem;
      font-size: 2rem;
    }

    .book-download-links {
      background: #eee;
      padding: 10px;
      border-radius: 2px;
      margin-bottom: 1em;
      .section__title {
        font-weight: 600;
        margin-block-end: 0.25em;
        &:before {
          height: 0px;
        }
      }
    }
    .book-download-option {
      margin-block-end: 1em;
      p {
        font-size: 0.85rem;
		margin-bottom: 0px;
      }
      &.last {
        margin-block-end: 0px;
        p {
          margin-block-end: 0px;
        }
      }
    }
    @media (min-width: 48em) {
      h3.section__title {
        font-size: 2rem
      }
      .book-download-links {
        padding: 20px;
        border-radius: 4px;
      }
      .book-download-option {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.container {
	width: min(90%, 64em);
	margin-inline: auto;
}

li {
	list-style: none;
	transition: calc(var(--util-speed) * 2.5);

	
}

a {
	color: inherit;
	text-decoration: none;
}

header, .section.--social {
	background: hsl(var(--clr-dark));
}

header, nav {
	z-index: 999;
}

header {
	padding: 1em 0;
	height: var(--util-header-height);
	position: fixed;
	width: 100%;
	inset-block-start: 0;
	inset-inline-start: 0;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}
}

.logo {
	color: hsl(var(--clr-light));
	font-family: var(--ff-mono);
	img {
		max-width: 200px;
	}
}

.nav-toggle {
	cursor: pointer;

	.fa-bars {
		color: hsl(var(--clr-light));
		font-size: 1.25rem;
	}

	@media (min-width: 64.5em) {
		display: none;
	}
}

.nav {
	position: fixed;
	inset-block-start: var(--util-header-height);
	inset-inline-start: 0;
	width: 100%;
	background: hsl(var(--clr-dark));
	transform: translateX(100%);
	transition: calc(var(--util-speed) * 2.5);
	color: hsl(var(--clr-light));
	height: calc(100vh - var(--util-header-height));
	padding-inline: 5%;

	&.nav-open {
		transform: translateX(0);
	}

	text-align: right;

	li {
		text-transform: uppercase;
	}
	
	.follow {
		margin-block: 4em 1em;
		font-size: 1rem;

		@media (min-width: 48em) {
			font-size: 1.5rem;
		}
	}

	@media (min-width: 64.5em) {
		all: unset;

		ul {
			display: flex;
			align-items: baseline;
			gap: 3em;
			margin: 0;
			color: hsl(var(--clr-light));
		}

		.follow, .mobile-social-nav {
			display: none;
		}
	}
}

.mobile-social-nav, .social-nav {
	li {
		&:hover, &:focus {

			&.--discord {
				background: hsl(var(--clr-discord));
			}	
			&.--github {
				background: hsl(var(--clr-github));
			}	
			&.--discord {
				background: hsl(var(--clr-discord));
			}	
			&.--instagram {
				background: hsl(var(--clr-instagram));
			}		
			&.--blog {
				background: var(--clr-blog);
			}
			&.--web {
				background: var(--clr-web);
			}
			&.--reddit {
				background: hsl(var(--clr-reddit));
			}
			&.--newsletter {
				background: var(--clr-newsletter);
			}
			&.--telegram {
				background: hsl(var(--clr-telegram));
			}
			&.--tiktok {
				background: hsl(var(--clr-tiktok));
			}
			&.--twitch {
				background: hsl(var(--clr-twitch));
			}
		}
	}
}

.mobile-nav {
	margin-block-start: 4em;

	& > * + * {
		margin-block-start: 1em;

		@media (min-width: 64.5em) {
			margin: 0;
		}
	}

	&__link {
		font-size: 1rem;
		transition: calc(var(--util-speed) * 2.5);

		@media (min-width: 48em) {
			font-size: 1.5rem;
		}

		@media (min-width: 64.5em) {
			font-size: 1rem;
		}

		&:hover, &:focus {
			color: hsl(var(--clr-accent));
			font-weight: var(--fw-bold);
		}
	}
}

.mobile-social-nav {
	display: grid;
	grid: auto / repeat(4, 3rem);
	justify-content: end;
	gap: 0.5em;

	li {
		display: grid;
		place-content: center;
		cursor: pointer;
		width: 100%;
		aspect-ratio: 1;
		background: hsl(var(--clr-light) / 0.2);

		i {
			color: hsl(var(--clr-dark));
			font-size: 1.5rem;
		}
	}
}

.social-nav {
	display: grid;
	grid: auto / repeat(4, 1fr);
	gap: 0.5em;

	&__item {
		width: 100%;
		aspect-ratio: 1;
		background: hsl(var(--clr-light) / 0.2);
	}

	&__link {
		width: 100%;
		height: 100%;
		display: grid;
		place-content: center;

		i {
			font-size: 2rem;
			color: hsl(var(--clr-dark));

			@media (min-width: 64.5em) {
				font-size: 3rem;
			}
		}
	}

	

	// @media (min-width: 64.5em) {
	// 	width: 50%;
	// 	margin: var(--util-header-height) auto 0;
	// }
}

.impressum {
	width: 100%;
	margin-block: 4rem -2rem;
	grid-column: 1 / -1;
	display: grid;
	gap: 1em;
	color: hsl(var(--clr-light) / 0.2);
	font-size: 0.875rem;
	text-align: right;
	line-height: 1.5;

	.copyright {
		font-size: 0.75rem;
	}
}

/* Section container for cards */
.section.--cards .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Space between cards */
    gap: 20px; /* Adds gap between cards */
    width: min(90%, 64em); /* Container width */
    margin-inline: auto; /* Center the container */
}

.section.--cards {
	background-color: #f8f8f8;
}

/* Card container */
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 4px 8px var(--box-shadow-color);
    background-color: var(--card-bg-color);
    box-sizing: border-box;
    flex-basis: calc(33.33% - 20px); /* Ensures cards are one-third width of container minus gap */
    flex-grow: 0; /* Prevents the last card from expanding to fill the row */
    margin-bottom: 20px;
}

/* Title section */
.card-title {
    padding: 20px;
}

.card-title a {
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    color: hsl(var(--clr-accent));
    margin-bottom: 0px;
}

.card-title a:hover {
    text-decoration: underline;
    color: var(--title-hover-color);
}

/* Description section */
.card-description {
    color: var(--description-color);
    margin-bottom: 20px;
    flex-grow: 1;
    padding: 0px 20px 0px 20px;
	@include font-settings();
}

.card a {
    display: block; /* Makes the link behave like a block */
    text-decoration: none; /* Ensures no underline */
}

/* Ensure the image fills its container */
.card-image {
    display: block; /* Removes inline space below the image */
    width: 100%; /* Ensure it fills the container width */
    height: auto; /* Keeps the image aspect ratio */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
}

/* Mobile layout */
@media (max-width: 768px) {
    .section.--cards .container {
        flex-direction: column; /* Stack cards vertically on mobile */
    }

    .section.--cards .card {
        flex-basis: 100%; /* Full width on mobile */
        margin: 10px 0; /* Adds margin between stacked cards */
    }
}

/* Tablet/laptop layout */
@media (min-width: 769px) and (max-width: 1200px) {
    .section.--cards .card {
        flex-basis: calc(50% - 20px); /* 2 cards per row on tablets/laptops */
        margin-bottom: 20px;
    }
}

/* Desktop layout */
@media (min-width: 1201px) {
    .section.--cards .card {
        flex-basis: calc(33.33% - 20px); /* 3 cards per row on larger screens */
        margin-bottom: 20px;
    }
}

.about-me{
	p {
	grid-area: auto / 3 / auto / -3;
	font-size: 1.125rem;
	line-height: 1.8;
	margin-block-end: 2em;
	}
	a {
    font-weight: var(--fw-bold);
    transition: calc(var(--util-speed)* 2.5);
    color: hsl(var(--clr-accent));
	}
	img {
		max-width: 100%;
		margin-bottom: 2em;

	}
}

section.--mission {

/* General split layout for desktop */
.split {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.split__image {
    flex: 1;
    padding-right: 20px;
}

.split__text {
    flex: 1;
	p {
		line-height: 1.8;
		margin-block-end: 3rem;
		margin: 0; /* No need for media queries */
		font-size: 1.125rem; /* Uniform font size without responsiveness */
	}
}

.mission-image {
    max-width: 100%;
    height: auto;
}

/* Mobile layout */
@media (max-width: 768px) {
    .split {
        flex-direction: column; /* Stack elements vertically */
    }

    .split__image {
        padding-right: 0;
        margin-top: 20px;
    }

    .split__text {
        margin-bottom: 20px; /* Space between text and image */
    }
}
}